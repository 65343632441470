import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Header from './Component/Header/Header';
import Footer from './Component/Footer/Footer';
import Home from './Component/page/Home';
import Sattamatkaking from './Component/page/Sattamatkaking';
import Sattakingrecordchartgali from './Component/page/Sattakingrecordchartgali';
import Sattamatka from './Component/page/Sattamatka';
import Blacksattakingleaknumber from './Component/page/Blacksattakingleaknumber';


import Resulthistory from './Component/page/Resulthistory';
import Market from './Component/page/Market';
import Result from './Component/page/Result';
import Index2 from './Component/page/Index2';

import Privacypolicy from './Component/page/Privacypolicy';
// import Footer from './Component/page/Footer';

import Sattakingup from './Component/page/Sattakingup';
import Sattasmart from './Component/page/Sattasmart';

import Upgameking from './Component/page/Upgameking';
import Vipsattaking from './Component/page/Vipsattaking';
import Disclaimer from './Component/page/Disclaimer';
import Sitemap from './Component/page/Sitemap';
// import Result2023 from './Component/page/Result2023';
// import Projectcounter from './Component/page/Projectcounter';
// import Aboutus from './Component/page/Aboutus';
// import Contactus from './Component/page/Contactus';
// import Carrer from './Component/page/Carrer';
// import Login from './Component/page/Login';
// import Register from './Component/page/Register';

function App() {
  return (
  <>
      
    <BrowserRouter>
    
    <Routes>
   
       <Route
       path="/*"
       element={
         <div>
           <Footer/>
           <Routes>
               <Route path="/" element={<Home/>}></Route>
               <Route path="/Sattamatkaking" element={<Sattamatkaking/>}></Route>
               <Route path="/Sattakingrecordchartgali" element={<Sattakingrecordchartgali/>}></Route>
               <Route path="/Sattamatka" element={<Sattamatka/>}></Route>
               <Route path="/Blacksattakingleaknumber" element={<Blacksattakingleaknumber/>}></Route>
   
               <Route path="/Index2" element={<Index2/>}></Route>
             
               <Route path="/Privacypolicy" element={<Privacypolicy/>}></Route>
               <Route path="/Market" element={<Market/>}></Route>
               <Route path="/Result" element={<Result/>}></Route>
    
               <Route path="/Sattakingup" element={<Sattakingup/>}></Route>
               <Route path="/Sattasmart" element={<Sattasmart/>}></Route>
               <Route path="/Resulthistory" element={<Resulthistory/>}></Route>
        
               <Route path="/Upgameking" element={<Upgameking/>}></Route>
               <Route path="/Vipsattaking" element={<Vipsattaking/>}></Route>
               <Route path="/Disclaimer" element={<Disclaimer/>}></Route>
               <Route path="/Sitemap" element={<Sitemap/>}></Route>
               {/* <Route path="/Projectcounter" element={<Projectcounter/>}></Route>
               <Route path="/Aboutus" element={<Aboutus/>}></Route>
               <Route path="/Contactus" element={<Contactus/>}></Route>
               <Route path="/Carrer" element={<Carrer/>}></Route>
               <Route path="/Login" element={<Login/>}></Route>
               <Route path="/Register" element={<Register/>}></Route> */}
           </Routes>
           {/* <Footer/> */}
         </div>
       }
       >
       </Route>
    </Routes>
    </BrowserRouter>
  </>
  );
}

export default App;
