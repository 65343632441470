




import wallet from "../../assets/image/dmca-badge-w200-5x1-06.png";
import statakinglogo from "../../assets/image/statakinglogo.png";
import gift from "../../assets/image/arrow.gif";
import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

function Upgameking() {
  // const [user, setUsers] = useState(null);
  const [userr, setUserss] = useState(null);
  const [useadvertisement, setadvertisement] = useState(null);
  const [useadvertisement1, setadvertisement1] = useState(null);
  const [usefetchAdvertisements2, setfetchAdvertisements2] = useState(null);
  const [useallMarketData, setuseallMarketData] = useState(null);
  const [usefetchAdvertisements3, setfetchAdvertisements3] = useState(null);
  const navigate = useNavigate();

  const [time, setTime] = useState(null);
  console.warn(time);

  const fetchAdvertisements1 = async () => {
    try {
      const response = await fetch(
        `https://sattakingresult.xyz/ResultApi/public/api/fetchAdvertisements1`,
        { method: "post" }
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      console.warn(data); // Log the response data
      setadvertisement1(data);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const fetchAdvertisements2 = async () => {
    try {
      const response = await fetch(
        `https://sattakingresult.xyz/ResultApi/public/api/fetchAdvertisements2`,
        { method: "post" }
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      console.warn(data); // Log the response data
      setfetchAdvertisements2(data);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const getAllMarketData = async () => {
    try {
      const response = await fetch(
        `https://sattakingresult.xyz/ResultApi/public/api/getAllMarketData`,
        { method: "post" }
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      console.warn(data); // Log the response data
      setuseallMarketData(data);
      // settime(data);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const fetchAdvertisements3 = async () => {
    try {
      const response = await fetch(
        `https://sattakingresult.xyz/ResultApi/public/api/fetchAdvertisements3`,
        { method: "post" }
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      console.warn(data); // Log the response data
      setfetchAdvertisements3(data);
      // settime(data);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    loaduser();
    markets();
    results();
    fetchAdvertisements3();
    getAllMarketData();
    fetchAdvertisements2();
    fetchAdvertisements1();
    loaduser1();
  }, []);

  const [usesetmarket, setmarket] = useState(null);

  const [resultsJan, setresultsJan] = useState(null);
  const [UniqueDatesJan, setUniqueDatesJan] = useState([]);

  const [resultsFeb, setresultsFeb] = useState(null);
  const [UniqueDatesFeb, setUniqueDatesFeb] = useState([]);

  const [resultsMar, setresultsMar] = useState(null);
  const [UniqueDatesMar, setUniqueDatesMar] = useState([]);

  const [resultsApr, setresultsApr] = useState(null);
  const [UniqueDatesApr, setUniqueDatesApr] = useState([]);

  const [resultsMay, setresultsMay] = useState(null);
  const [UniqueDatesMay, setUniqueDatesMay] = useState([]);

  const [resultsJun, setresultsJun] = useState(null);
  const [UniqueDatesJun, setUniqueDatesJun] = useState([]);

  const [resultsJul, setresultsJul] = useState(null);
  const [UniqueDatesJul, setUniqueDatesJul] = useState([]);

  const [resultsAug, setresultsAug] = useState(null);
  const [UniqueDatesAug, setUniqueDatesAug] = useState([]);

  const [resultsSep, setresultsSep] = useState(null);
  const [UniqueDatesSep, setUniqueDatesSep] = useState([]);

  const [resultsOct, setresultsOct] = useState(null);
  const [UniqueDatesOct, setUniqueDatesOct] = useState([]);

  const [resultsNov, setresultsNov] = useState(null);
  const [UniqueDatesNov, setUniqueDatesNov] = useState([]);


  const [resultsDec, setresultsDec] = useState(null);
  const [UniqueDatesDec, setUniqueDatesDec] = useState([]);
  const results = async () => {
    try {
      const response = await fetch(
        `https://sattakingresult.xyz/ResultApi/public/api/market-year-results`,
        { method: "post" }
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      console.warn(data); // Log the response data

      // Jan the response data
      setresultsJan(data.data);
      console.warn(data.data)
      const datesJan = data.data.Jan.map((entry) => entry.date);
      const uniqueDatesJan = [...new Set(datesJan)];
      setUniqueDatesJan(uniqueDatesJan);
      //Feb the response data
      setresultsFeb(data.data);
      const datesFeb = data.data.Feb.map((entry) => entry.date);
      const uniqueDatesFeb = [...new Set(datesFeb)];
      setUniqueDatesFeb(uniqueDatesFeb);
      //Mar the response data
      setresultsMar(data.data);
      const datesMar = data.data.Mar.map((entry) => entry.date);
      const uniqueDatesMar = [...new Set(datesMar)];
      setUniqueDatesMar(uniqueDatesMar);
      //Apr the response data
      setresultsApr(data.data);
      const datesApr = data.data.Apr.map((entry) => entry.date);

      const uniqueDatesApr = [...new Set(datesApr)];
      setUniqueDatesApr(uniqueDatesApr);
      //May the response data
      setresultsMay(data.data);
      const datesMay = data.data.May.map((entry) => entry.date);

      const uniqueDatesMay = [...new Set(datesMay)];
      setUniqueDatesMay(uniqueDatesMay);
      //Jun the response data
      setresultsJun(data.data);
      const datesJun = data.data.Jun.map((entry) => entry.date);

      const uniqueDatesJun = [...new Set(datesJun)];
      setUniqueDatesJun(uniqueDatesJun);
      console.warn(data.data)


      //Jul the response data
      setresultsJul(data.data);
      const datesJul = data.data.Jul.map((entry) => entry.date);

      const uniqueDatesJul = [...new Set(datesJul)];
      setUniqueDatesJul(uniqueDatesJul);
      console.warn(data.data)
      //Aug the response data
      setresultsAug(data.data);
      const datesAug = data.data.Aug.map((entry) => entry.date);

      const uniqueDatesAug = [...new Set(datesAug)];
      setUniqueDatesAug(uniqueDatesAug);
      console.warn(data.data)

      //Sep the response data
      setresultsSep(data.data);
      const datesSep = data.data.Sep.map((entry) => entry.date);

      const uniqueDatesSep = [...new Set(datesSep)];
      setUniqueDatesSep(uniqueDatesSep);
      console.warn(data.data)


      //Oct the response data
      setresultsOct(data.data);
      const datesOct = data.data.Oct.map((entry) => entry.date);

      const uniqueDatesOct = [...new Set(datesOct)];
      setUniqueDatesOct(uniqueDatesOct);
      console.warn(data.data)


      //Nov the response data
      setresultsNov(data.data);
      const datesNov = data.data.Nov.map((entry) => entry.date);

      const uniqueDatesNov = [...new Set(datesOct)];
      setUniqueDatesNov(uniqueDatesNov);
      console.warn(data.data)

      //Dec the response data
      setresultsDec(data.data);
      const datesDec = data.data.Dec.map((entry) => entry.date);

      const uniqueDatesDec = [...new Set(datesDec)];
      setUniqueDatesDec(uniqueDatesDec);
      console.warn(data.data)






      const livetime = data.market_name; // Accessing livetime from the response data

      setmarket(livetime);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const markets = async () => {
    try {
      const response = await fetch(
        `https://sattakingresult.xyz/ResultApi/public/api/markets`,
        { method: "POST" }
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      console.warn(data); // Log the response data
      setadvertisement(data); // Update state with the response data
      // settime(response);
      const livetime = data.livetime; // Accessing livetime from the response data
      setTime(livetime);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const [usemarketname, setusemarketname] = useState("");

  const [useyearr, setyearr] = useState("");
  const [titlee, settitlee] = useState("");

  const loaduser = async () => {
    // const market_id = localStorage.getItem("market_id");
    // alert(market_id)
    // console.warn(market_id)

    // alert(user_id);
    let url = `https://sattakingresult.xyz/ResultApi/public/api/satta-chart-record2024`;
    // console.warn(url);
    // const formData = new FormData();
    // formData.append("market_id", market_id);
    // alert(dev_id)
    var config = {
      method: "POST",
      url: url,
    };
    axios
      .post(url, config)
      .then(function (response) {
        const res = JSON.stringify(response.data);
        const objectRes = JSON.parse(res);
        setusemarketname(objectRes);
        console.warn(objectRes);

        const year = objectRes.data.year;
        const title = objectRes.data.title;
        // alert(livetime)
        setyearr(year);
        settitlee(title);
      })
      .finally(() => {
        // setTimeout(() => {
        // }, 1000);
      });
  };

  const handleClick1 = async (market_id) => {
    navigate("/Result");
    localStorage.setItem("marketchart_id", market_id);
  };

  const [usemarketnamee, setusemarketnamee] = useState("");
  const [useyear, setyear] = useState("");
  const [title, settitle] = useState("");
  //  console.log(usetitleyear)

  const loaduser1 = async () => {
    let url = `https://sattakingresult.xyz/ResultApi/public/api/satta-chart-record2023`;

    var config = {
      method: "POST",
      url: url,
    };
    axios
      .post(url, config)
      .then(function (response) {
        const res = JSON.stringify(response.data);
        const objectRes = JSON.parse(res);
        setusemarketnamee(objectRes);

        const year = objectRes.data.year;
        const title = objectRes.data.title;
        // alert(livetime)
        setyear(year);
        settitle(title);
      })
      .finally(() => {
        // setTimeout(() => {
        // }, 1000);
      });
  };

  const handleClick2 = async (market_id) => {
    navigate("/Market");
    localStorage.setItem("marketchart_id", market_id);
  };

  return (
    <>
      {/* Google Tag Manager */}
      {/*  */}
      {/* End Google Tag Manager */}
      <link rel="canonical" href="/" />
      <meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />
      <title>
        SATTA KING | SATTA KING | Gali satta | Satta result | Disawar satta
      </title>
      <meta
        name="Description"
        content=" SATTA KING is a type of lottery game officially called Satta. We provide the fastest results for  SATTA KING, SATTA KING , Disawar Satta, and Satta Result Online."
      />
      <meta
        name="Keywords"
        content="SATTA KING, Gali satta,  SATTA KING, Satta result, sattaking, Satta, sattaresult, Satta matka, Matka result, Faridabad satta, 
        Gaziyabad satta, Disawar satta,  SATTA KING up,  SATTA KING result,  SATTA KING online, Gali result, Desawar result,  SATTA KING chart,  SATTA KING live,
         Deshawar live result, Gali live result, Satta matka, Satta matka king,  SATTA KING 2022 chart,  SATTA KING desawar,  SATTA KING gali, Satta Number, Satta Game
         , Gali Number, Delhi  SATTA KING, Satta Bazar, Black SATTA KING, Gali Single Jodi, Black Satta Result, Gali  SATTA KING result, Disawar  SATTA KING result, Faridabad 
         SATTA KING result, , SATTA KING, Gaziyabad  SATTA KING"
      />
      <link rel="stylesheet" href="boostrapp.css" />
      <link
        href="css/style8bcf8bcf.css?id=30"
        rel="stylesheet"
        type="text/css"
      />
      <link
        href="background3a9f3a9f.css?id=6"
        rel="stylesheet"
        type="text/css"
      />
      <link
        rel="icon"
        href="images/favicon.png"
        type="image/png"
        sizes="16x16"
      />
      <meta name="robots" content="index, follow" />
      <link rel="alternate" hrefLang="en" href="sattaking786.html" />
      <meta name="viewport" content="width=device-width" />
      {/*  */}
      <meta
        name="google-site-verification"
        content="jelo6kLqlf-aQgsFEZVxmhbB4SA-v2PJXN0ZCTaOCYA"
      />

      <style
        dangerouslySetInnerHTML={{
          __html:
            "\n        .tr1 {\n            background-color: #FFFFFF;\n            color: #000000;\n        }\n\n        p {\n            margin: 0 0 0px;\n        }\n\n        .border.row div.col-md-6.col-sm-6.gamefull:nth-of-type(7n + 1) {\n            background: #bf5010;\n            width: 100%;\n            min-height: auto;\n        }\n\n        .border.row div.col-md-6.col-sm-6:nth-of-type(7n + 1) a,\n        .border.row div.col-md-6.col-sm-6:nth-of-type(7n + 1) .gamefull-result {\n            color: #fff;\n        }\n\n        .border.row div.col-md-6.col-sm-6:nth-of-type(7n + 1) p {\n            color: yellow;\n        }\n\n        .pages {\n            height: 55px;\n            font-weight: 600;\n        }\n\n        .king {\n            width: 100%;\n            background-color: yellow;\n            text-align: center;\n            margin-top: 18px;\n            margin-bottom: 18px;\n        }\n\n        .king h1 {\n            font-size: 40px;\n            font-weight: bold;\n            color: #000;\n            padding: 6px;\n        }\n    ",
        }}
      />

      <div></div>
<div className="table-resonsive">
      <table className="pages">
        <tbody>
          <tr>
            <td width="14%">
              <a title=" SATTA KING" href="/">
                HOME
              </a>
            </td>
            <td width="24%">
              <a title=" SATTA KING 786" href="sattamatkaking">
                SATTA KING 786
              </a>
            </td>
            <td width="24%">
              <a href="Sattakingrecordchartgali">SATTA CHART</a>
            </td>
            <td width="24%">
              <a href="Sattamatka">SATTA MATKA</a>
            </td>
            <td width="24%">
              <a href="Blacksattakingleaknumber">SATTA LEAK</a>
            </td>
          </tr>
        </tbody>
      </table>
      </div>

      <div className="king" style={{ padding: "5px" }}>
        <img style={{ margin: "auto", maxWidth: "85px" }} src={statakinglogo} />
      </div>
      <div className="drag">
        <h2> SATTA KING BEST SITE SATTA RESULT</h2>
        <a href="/">WWW.SATTA-NO.COM</a>
      </div>
      <div align="center">
        <div className="style1">
          <p style={{ color: "black", fontSize: 24, fontWeight: "bold" }}>
            {/* 08 April 2024 03:58 PM */}
            {time}
          </p>
          <p className="style1-heading"> SATTA KING Live Result Today</p>
          <p className="livegame"></p>
          <p
            className="liveresult"
            style={{ color: "balck", fontSize: "24px", fontWeight: "bold" }}
          ></p>
          <div className="width_full_one">
            <div className="container-fluid">
              <div className="row ">
                {useadvertisement &&
                  useadvertisement.data &&
                  useadvertisement.data.livetodayResult &&
                  useadvertisement.data.livetodayResult.length > 0 && (
                    <React.Fragment>
                      {useadvertisement.data.livetodayResult.map((market, index) => (
                        <div className="col-md-12 p-0 col-sm-12 col-xs-12" key={index}>
                          <div>
                            <p className="livegame">{market.market_name}</p>
                            <p className="liveresult">{market.result === "" ? "Wait" : market.result}</p>
                          </div>
                        </div>
                      ))}

                    </React.Fragment>
                  )}
              </div>
            </div>
          </div>

          <p />
        </div>
      </div>
      <div className="drag">
        <img style={{ margin: "auto" }} src={wallet} />
      </div>
      <div
        style={{
          color: "#000",
          fontWeight: "bold",
          fontStyle: "bold",
          fontSize: "large",
          textDecoration: "none",
          margin: 3,
          padding: 10,
          borderRadius: 10,
          textAlign: "center",
        }}
      >
        <p style={{ fontSize: 25, color: "#ffffff", paddingBottom: 8 }}>
          आज की लीक जोडी
        </p>
      </div>
      {/*  */}
      {/* <div class="gcse-search"></div> */}

      {useadvertisement1 &&
        useadvertisement1.data &&
        useadvertisement1.data.advertisement &&
        useadvertisement1.data.advertisement.length > 0 && (
          <React.Fragment>
            {useadvertisement1.data.advertisement.map((market, index) => (
              <div
                style={{
                  backgroundColor: "#fff",
                  color: "#000",
                  fontWeight: "bold",
                  fontStyle: "bold",
                  fontSize: "large",
                  textDecoration: "none",
                  borderWidth: 3,
                  borderColor: "red",
                  borderStyle: "outset",
                  margin: 3,
                  padding: 10,
                  borderRadius: 10,
                  textAlign: "center",
                }}
              >
                <div key={index}>
                  <p style={{ fontSize: 16, color: "red" }}>👑</p>

                  {/* Example of setting HTML content */}
                  <div
                    dangerouslySetInnerHTML={{ __html: market.description }}
                  ></div>
                  <p style={{ fontSize: 16, color: "red" }}>👑</p>

                  <p
                    style={{ fontSize: 16, color: "blue" }}
                    className="d-flex justify-content-center"
                  >
                    👑{market.name}💣
                  </p>

                  <p style={{ fontSize: 16, color: "#000" }}>{market.post}👍</p>
                  <p style={{ fontSize: 16, color: "#000" }}>
                    {market.mobile}💵
                  </p>
                  <a href={`tel:${market.mobile}`}>
                    <button
                      style={{
                        height: 40,
                        width: "auto",
                        backgroundColor: "red",
                        color: "#FFF",
                        border: "solid 3px red",
                        borderRadius: 20,
                      }}
                    >
                      <font size="4px">
                        <b>CALL NOW</b>
                      </font>
                    </button>
                  </a>

                  <a target="_blank" href={`https://wa.me/+${market.mobile}`}>
                    {" "}
                    <button
                      style={{
                        height: 40,
                        width: "auto",
                        backgroundColor: "#18a707",
                        color: "#FFF",
                        border: "solid 3px #18a707",
                        borderRadius: 20,
                      }}
                    >
                      <font size="4px">
                        <b>WHATSAPP CHAT</b>
                      </font>
                    </button>
                  </a>
                </div>
              </div>
            ))}
          </React.Fragment>
        )}

      <div
        style={{
          color: "#000",
          fontWeight: "bold",
          fontStyle: "bold",
          fontSize: "large",
          textDecoration: "none",
          margin: 3,
          padding: 10,
          borderRadius: 10,
          textAlign: "center",
        }}
      >
        <p style={{ fontSize: 25, color: "#ffffff", paddingBottom: 8 }}>
          आज की लीक जोडी
        </p>
        <a href="tel:>+917737109">
          <button
            style={{
              height: 40,
              width: "auto",
              backgroundColor: "red",
              color: "#FFF",
              border: "solid 3px red",
              borderRadius: 20,
            }}
          >
            <font size="4px">
              <b>CALL NOW</b>
            </font>
          </button>
        </a>
        <a target="_blank" href="https://wa.me/+91773709">
          <button
            style={{
              height: 40,
              width: "auto",
              backgroundColor: "#18a707",
              color: "#FFF",
              border: "solid 3px #18a707",
              borderRadius: 20,
              marginLeft: "5px",
            }}
          >
            <font size="4px">
              <b>WHATSAPP CHAT</b>
            </font>
          </button>
        </a>
      </div>
      {/* Sattano.com */}
      <ins
        className="adsbygoogle"
        style={{ display: "block" }}
        data-ad-client="ca-pub-7108667654778024"
        data-ad-slot={6000997688}
        data-ad-format="auto"
        data-full-width-responsive="true"
      />
      {/*
       */}
      <div className="bookmark">
        <font>
          Apne Phone Pe Sabse Pehele Result Pane K Lie Site ☆ BOOKMARK Karein
        </font>
      </div>

      {usefetchAdvertisements2 &&
        usefetchAdvertisements2.data &&
        usefetchAdvertisements2.data.advertisement &&
        usefetchAdvertisements2.data.advertisement.length > 0 && (
          <React.Fragment>
            {usefetchAdvertisements2.data.advertisement.map((market, index) => (
              <div
                style={{
                  backgroundColor: "#fff",
                  color: "#000",
                  fontWeight: "bold",
                  fontStyle: "bold",
                  fontSize: "large",
                  textDecoration: "none",
                  borderWidth: 3,
                  borderColor: "blue",
                  borderStyle: "outset",
                  margin: 3,
                  padding: 10,
                  borderRadius: 10,
                  textAlign: "center",
                }}
              >
                <div key={index}>
                  <div>
                    {/* Example of setting HTML content */}
                    <div
                      dangerouslySetInnerHTML={{ __html: market.description }}
                    ></div>
                    <p style={{ fontSize: 16, color: "#000" }}>{market.name}</p>
                    <p style={{ fontSize: 16, color: "red" }}>{market.post}</p>
                    <p style={{ fontSize: 16, color: "green" }}>
                      {market.mobile}
                    </p>
                    <a href={`tel:${market.mobile}`}>
                      <button
                        style={{
                          height: 40,
                          width: "auto",
                          backgroundColor: "red",
                          color: "#FFF",
                          border: "solid 3px red",
                          borderRadius: 20,
                        }}
                      >
                        <font size="4px">
                          <b>CALL NOW</b>
                        </font>
                      </button>
                    </a>
                    <a
                      target="_blank"
                      href={`https://wa.me/+${market.mobile}`}
                      style={{
                        marginLeft: "10px",
                      }}
                    >
                      <button
                        style={{
                          height: 40,
                          width: "auto",
                          backgroundColor: "#18a707",
                          color: "#FFF",
                          border: "solid 3px #18a707",
                          borderRadius: 20,
                        }}
                      >
                        <font size="4px">
                          <b>WHATSAPP CHAT</b>
                        </font>
                      </button>
                    </a>
                  </div>
                </div>
              </div>
            ))}
          </React.Fragment>
        )}



      <div className="container-fluid">
        <div className="border row p-0">



          {useallMarketData && useallMarketData.data && useallMarketData.data.allMarketData && useallMarketData.data.allMarketData.length > 0 && (
            <React.Fragment>
              {useallMarketData.data.allMarketData.map((market, index) => (
                <div className="col-md-6 p-0 col-sm-6 col-xs-6 gamefull" >
                  <div key={index}>
                    <Link style={{ fontSize: 16, color: "red" }}> {market.market_name} </Link>
                    <p style={{ fontSize: 16, color: "#000" }} className="yellow_color"> {'('} {market.market_sunday_time_open} {')'}</p>
                    <div className="d-flex justify-content-center">

                      <p style={{ fontSize: 16, color: "#000" }} className="gamefull-result"> {market.result} </p>
                      <img src={gift} style={{ marginLeft: "10px", marginRight: "10px", color: "#000", width: "16px", height: "16px" }} />
                      <p style={{ fontSize: 16, color: "#000" }} className="gamefull-result"> {market.result2} </p>
                    </div>


                  </div>

                </div>
              ))}
            </React.Fragment>
          )}










        </div>
      </div>















      {resultsJun && resultsJun.Jun && resultsJun.Jun.length > 0 && (
        <>
          <div className="chart_record">
            {UniqueDatesJun[0] && (
              <h2
                style={{ fontSize: 22, padding: "10px", background: "#F79E2F" }}
                className="align"
              >
                {new Date(UniqueDatesJun[0]).toLocaleString("default", {
                  month: "short",
                })}{" "}
                RECORD CHART {new Date(UniqueDatesJun[0]).getFullYear()}
              </h2>
            )}

            <p style={{ color: "red" }}> {usesetmarket} </p>
          </div>
          <div className="table-responsive">
            
          <table className="nrtable">
            <tbody>
              <tr style={{ border: "2px solid blue" }}>
                <th className="nrtable-fon">Date</th>
                {UniqueDatesJun.slice(0, 1).map((date) => (
                  <React.Fragment>
                    {resultsJun.Jun
                      .filter((entry) => entry.date === date)
                      .map((entry) => (
                        <th>
                          <div align="center" className="style1 bg-transparent">
                            <div>
                              <p className="style6 text-white">
                                {entry.market_name}
                              </p>
                            </div>
                          </div>
                        </th>
                      ))}
                  </React.Fragment>
                ))}
              </tr>

              {UniqueDatesJun.map((date) => (
                <React.Fragment>
                  <td style={{ fontSize: 16, color: "black" }}>
                    {new Date(date).getDate()} - {new Date(date).getMonth() + 1}
                  </td>
                  {resultsJun.Jun
                    .filter((entry) => entry.date === date)
                    .map((entry) => (
                      <React.Fragment>
                        <td style={{ fontSize: 16, color: "black" }}>
                          <p style={{ fontSize: 16, color: "black" }}>
                            {entry.result}
                          </p>
                        </td>
                      </React.Fragment>
                    ))}
                  <tr className="tr1"></tr>
                </React.Fragment>
              ))}
            </tbody>
          </table>
          
          </div>
        </>
      )}


      {resultsMay && resultsMay.May && resultsMay.May.length > 0 && (
        <>
          <div className="chart_record">
            {UniqueDatesMay[0] && (
              <h2
                style={{ fontSize: 22, padding: "10px", background: "#F79E2F" }}
                className="align"
              >
                {new Date(UniqueDatesMay[0]).toLocaleString("default", {
                  month: "short",
                })}{" "}
                RECORD CHART {new Date(UniqueDatesMay[0]).getFullYear()}
              </h2>
            )}

            <p style={{ color: "red" }}> {usesetmarket} </p>
          </div>
          <div className="table-responsive">

          <table className="nrtable">
            <tbody>
              <tr style={{ border: "2px solid blue" }}>
                <th className="nrtable-fon">Date</th>
                {UniqueDatesMay.slice(0, 1).map((date) => (
                  <React.Fragment>
                    {resultsMay.May
                      .filter((entry) => entry.date === date)
                      .map((entry) => (
                        <th>
                          <div align="center" className="style1 bg-transparent">
                            <div>
                              <p className="style6 text-white">
                                {entry.market_name}
                              </p>
                            </div>
                          </div>
                        </th>
                      ))}
                  </React.Fragment>
                ))}
              </tr>

              {UniqueDatesMay.map((date) => (
                <React.Fragment>
                  <td style={{ fontSize: 16, color: "black" }}>
                    {new Date(date).getDate()} - {new Date(date).getMonth() + 1}
                  </td>
                  {resultsMay.May
                    .filter((entry) => entry.date === date)
                    .map((entry) => (
                      <React.Fragment>
                        <td style={{ fontSize: 16, color: "black" }}>
                          <p style={{ fontSize: 16, color: "black" }}>
                            {entry.result}
                          </p>
                        </td>
                      </React.Fragment>
                    ))}
                  <tr className="tr1"></tr>
                </React.Fragment>
              ))}
            </tbody>
          </table>
          </div>
        </>
      )}
      {resultsApr && resultsApr.Apr && resultsApr.Apr.length > 0 && (
        <>
          <div className="chart_record">
            {UniqueDatesApr[0] && (
              <h2
                style={{ fontSize: 22, padding: "10px", background: "#F79E2F" }}
                className="align"
              >
                {new Date(UniqueDatesApr[0]).toLocaleString("default", {
                  month: "short",
                })}{" "}
                RECORD CHART {new Date(UniqueDatesApr[0]).getFullYear()}
              </h2>
            )}

            <p style={{ color: "red" }}> {usesetmarket} </p>
          </div>
          <div className="table-responsive">

          <table className="nrtable">
            <tbody>
              <tr style={{ border: "2px solid blue" }}>
                <th className="nrtable-fon">Date</th>
                {UniqueDatesApr.slice(0, 1).map((date) => (
                  <React.Fragment>
                    {resultsApr.Apr
                      .filter((entry) => entry.date === date)
                      .map((entry) => (
                        <th>
                          <div align="center" className="style1 bg-transparent">
                            <div>
                              <p className="style6 text-white">
                                {entry.market_name}
                              </p>
                            </div>
                          </div>
                        </th>
                      ))}
                  </React.Fragment>
                ))}
              </tr>

              {UniqueDatesApr.map((date) => (
                <React.Fragment>
                  <td style={{ fontSize: 16, color: "black" }}>
                    {new Date(date).getDate()} - {new Date(date).getMonth() + 1}
                  </td>
                  {resultsApr.Apr
                    .filter((entry) => entry.date === date)
                    .map((entry) => (
                      <React.Fragment>
                        <td style={{ fontSize: 16, color: "black" }}>
                          <p style={{ fontSize: 16, color: "black" }}>
                            {entry.result}
                          </p>
                        </td>
                      </React.Fragment>
                    ))}
                  <tr className="tr1"></tr>
                </React.Fragment>
              ))}
            </tbody>
          </table>
          </div>
        </>
      )}

      {resultsMar && resultsMar.Mar && resultsMar.Mar.length > 0 && (
        <>
          <div className="chart_record">
            {UniqueDatesMar[0] && (
              <h2
                style={{ fontSize: 22, padding: "10px", background: "#F79E2F" }}
                className="align"
              >
                {new Date(UniqueDatesMar[0]).toLocaleString("default", {
                  month: "short",
                })}{" "}
                RECORD CHART {new Date(UniqueDatesMar[0]).getFullYear()}
              </h2>
            )}

            <p style={{ color: "red" }}> {usesetmarket} </p>
          </div>
          <div className="table-responsive">

          <table className="nrtable">
            <tbody>
              <tr style={{ border: "2px solid blue" }}>
                <th className="nrtable-fon">Date</th>
                {UniqueDatesMar.slice(0, 1).map((date) => (
                  <React.Fragment>
                    {resultsMar.Mar
                      .filter((entry) => entry.date === date)
                      .map((entry) => (
                        <th>
                          <div align="center" className="style1 bg-transparent">
                            <div>
                              <p className="style6 text-white">
                                {entry.market_name}
                              </p>
                            </div>
                          </div>
                        </th>
                      ))}
                  </React.Fragment>
                ))}
              </tr>

              {UniqueDatesMar.map((date) => (
                <React.Fragment>
                  <td style={{ fontSize: 16, color: "black" }}>
                    {new Date(date).getDate()} - {new Date(date).getMonth() + 1}
                  </td>
                  {resultsMar.Mar
                    .filter((entry) => entry.date === date)
                    .map((entry) => (
                      <React.Fragment>
                        <td style={{ fontSize: 16, color: "black" }}>
                          <p style={{ fontSize: 16, color: "black" }}>
                            {entry.result}
                          </p>
                        </td>
                      </React.Fragment>
                    ))}
                  <tr className="tr1"></tr>
                </React.Fragment>
              ))}
            </tbody>
          </table>
          </div>
        </>
      )}
      {resultsFeb && resultsFeb.Feb && resultsFeb.Feb.length > 0 && (
        <>
          <div className="chart_record">
            {UniqueDatesFeb[0] && (
              <h2
                style={{ fontSize: 22, padding: "10px", background: "#F79E2F" }}
                className="align"
              >
                {new Date(UniqueDatesFeb[0]).toLocaleString("default", {
                  month: "short",
                })}{" "}
                RECORD CHART {new Date(UniqueDatesFeb[0]).getFullYear()}
              </h2>
            )}

            <p style={{ color: "red" }}> {usesetmarket} </p>
          </div>
          <div className="table-responsive">

          <table className="nrtable">
            <tbody>
              <tr style={{ border: "2px solid blue" }}>
                <th className="nrtable-fon">Date</th>
                {UniqueDatesFeb.slice(0, 1).map((date) => (
                  <React.Fragment>
                    {resultsFeb.Feb
                      .filter((entry) => entry.date === date)
                      .map((entry) => (
                        <th>
                          <div align="center" className="style1 bg-transparent">
                            <div>
                              <p className="style6 text-white">
                                {entry.market_name}
                              </p>
                            </div>
                          </div>
                        </th>
                      ))}
                  </React.Fragment>
                ))}
              </tr>

              {UniqueDatesFeb.map((date) => (
                <React.Fragment>
                  <td style={{ fontSize: 16, color: "black" }}>
                    {new Date(date).getDate()} - {new Date(date).getMonth() + 1}
                  </td>
                  {resultsFeb.Feb
                    .filter((entry) => entry.date === date)
                    .map((entry) => (
                      <React.Fragment>
                        <td style={{ fontSize: 16, color: "black" }}>
                          <p style={{ fontSize: 16, color: "black" }}>
                            {entry.result}
                          </p>
                        </td>
                      </React.Fragment>
                    ))}
                  <tr className="tr1"></tr>
                </React.Fragment>
              ))}
            </tbody>
          </table>
          </div>

        </>
      )}
      {resultsJan && resultsJan.Jan && resultsJan.Jan.length > 0 && (
        <>
          <div className="chart_record">
            {UniqueDatesJan[0] && (
              <h2
                style={{ fontSize: 22, padding: "10px", background: "#F79E2F" }}
                className="align"
              >
                {new Date(UniqueDatesJan[0]).toLocaleString("default", {
                  month: "short",
                })}{" "}
                RECORD CHART {new Date(UniqueDatesJan[0]).getFullYear()}
              </h2>
            )}

            <p style={{ color: "red" }}> {usesetmarket} </p>
          </div>
          <div className="table-responsive">


          <table className="nrtable">
            <tbody>
              <tr style={{ border: "2px solid blue" }}>
                <th className="nrtable-fon">Date</th>
                {UniqueDatesJan.slice(0, 1).map((date) => (
                  <React.Fragment>
                    {resultsJan.Jan
                      .filter((entry) => entry.date === date)
                      .map((entry) => (
                        <th>
                          <div align="center" className="style1 bg-transparent">
                            <div>
                              <p className="style6 text-white">
                                {entry.market_name}
                              </p>
                            </div>
                          </div>
                        </th>
                      ))}
                  </React.Fragment>
                ))}
              </tr>

              {UniqueDatesJan.map((date) => (
                <React.Fragment>
                  <td style={{ fontSize: 16, color: "black" }}>
                    {new Date(date).getDate()} - {new Date(date).getMonth() + 1}
                  </td>
                  {resultsJan.Jan
                    .filter((entry) => entry.date === date)
                    .map((entry) => (
                      <React.Fragment>
                        <td style={{ fontSize: 16, color: "black" }}>
                          <p style={{ fontSize: 16, color: "black" }}>
                            {entry.result}
                          </p>
                        </td>
                      </React.Fragment>
                    ))}
                  <tr className="tr1"></tr>
                </React.Fragment>
              ))}
            </tbody>
          </table>
          </div>
        </>
      )}


      <div class="king-heading">
        <h2 style={{ fontSize: 20, fontWeight: "bold" }}>
          {useyearr} {titlee}
        </h2>
      </div>

      {usemarketname &&
        usemarketname.data &&
        usemarketname.data.market_name &&
        usemarketname.data.market_name.length > 0 && (
          <React.Fragment>
            {usemarketname.data.market_name.map((market, index) => (
              <div className="list">
                <div key={index}>
                  <div className="d-flex justify-content-center">
                    <p
                      style={{ fontSize: 16, color: "#000" }}
                      className="gamefull-result"
                      onClick={() => {
                        handleClick1(market.market_id);
                      }}
                    >
                      {" "}
                      {market.market_name}{" "}
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </React.Fragment>
        )}
      <div class="king-heading">
        <h2 style={{ fontSize: 20, fontWeight: "bold" }}>
          {useyear} {title}
        </h2>
      </div>

      {usemarketnamee &&
        usemarketnamee.data &&
        usemarketnamee.data.market_name &&
        usemarketnamee.data.market_name.length > 0 && (
          <React.Fragment>
            {usemarketnamee.data.market_name.map((market, index) => (
              <div className="list ">
                <div key={index}>
                  <div className="d-flex justify-content-center">
                    <p
                      style={{ fontSize: 16, color: "#000" }}
                      className="gamefull-result"
                      onClick={() => {
                        handleClick2(market.market_id);
                      }}
                    >
                      {" "}
                      {market.market_name}{" "}
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </React.Fragment>
        )}

      <div className="content" style={{ backgroundColor: "#fff" }}>
        <h3> SATTA KING WARNING</h3>
        <p>
          {" "}
          साइट (satta-no.com) पर दिए गए रिजल्ट और लीक नंबर के लिए यह साइट
          जिम्मेदार नहीं है | यह साइट सिर्फ और सिर्फ मनोरंजन के लिए बनाई गई है ।
          यह साइट सट्टे से जुड़ी किसी भी गतिविधि को बढ़ावा नहीं देती । कृपया लीक
          नंबर के लिए हमें फोन कर के अपना समय खराब ना करें । सट्टा और जुआं जिस
          देश या राज्य में प्रतिबंधित है वहां के लोग हमारी साइट को ब्लॉक कर दें
          | किसी भी लाभ या हानि के लिए आप खुद जिम्मदार होंगे ।{" "}
        </p>
        <p>
          Dear user this site is not responsible for any SATTA KING result and
          Satta leak number all there board and SATTA KING result are sponsored.
          deal with leak number vendors and play the game at your own risk. we
          are not responsible for any profit and loss have done using the result
          of the site. we have developed the site just for entertainment
          purposes. we have just arranged all the Satta results in the SATTA
          KING record chart of all the best games of the Satta Matka market. we
          are not connected with any of the Satta Matka company. do not visit
          our site if the Satta Matka game is banned in your state or country.
        </p>
      </div>

      <> </>

      <div className="content">
        <h3> About satta-no.com ( SATTA KING) </h3>
        <p>
          Welcome to Satta-no.com our website is one of the leading websites of{" "}
          <a title=" SATTA KING" href="Sattakingrecordchartgali">
            SATTA KING
          </a>{" "}
          market, our site is developed in 2018 for showing the result of all
          Satta king games and SATTA KING record charts, we don't involve in any
          Satta playing activity. in our website our user can get Satta result
          super fast which help you to get fast Satta result in your mobile, you
          can find the Satta record of all best game of SATTA KING Bazar in our
          <a title=" SATTA KING" href="Sattakingrecordchartgali">
            SATTA KING record chart
          </a>{" "}
          page which is very user friendly and comfortable. in this page you can
          find the record chart of all best games of satta market like Rajkot
          satta king, bahadurghar satta, Charminar SATTA KING, Desawar,
          Faridabad, Ghaziabad, Gali, Disawar day, Mysor SATTA KING, Shri Hari
          Satta, Max Gold, Sher-E-Afghan, Rajshri Satta, Golden satta, Sundram,
          Upgameking Satta, Peshwar SATTA KING, Taj satta, Haryana, Hindustan,
          Sholapur, Chetak, Faizabad satta, Pawanputra Satta, Shri Ganesh, Arab
          Countey, Dream 7, UP SATTA KING, Gurgaon, Punjab day, Narmada, Mumbai
          Day, Shalimar, swaraj SATTA KING, Disawar 365, Allahabad, Meena Bazar,
          Tejas,{" "}
          <a title=" SATTA KING" href="Sattakingrecordchartgali">
            SATTA KING
          </a>
          , Lucky 7, Mirjpur, Kalash, Satta bazar, Gujrat Market, Super Satta
          and much more famous game of Satta bazar. we allow our user to update
          his own game result. If you want to add your own SATTA KING game in
          our site you have to contact the site admin. In our website you can
          also find the post regarding Satta leak number, these posts are posted
          by different Satta gusser having a wide experience in guessing and
          extracting upcoming jodies for old{" "}
          <a title=" SATTA KING" href="Sattakingrecordchartgali">
            Satta record chart
          </a>
          . these experts can help you to win any SATTA KING games. you can also
          win any game of SATTA KING by contacting them. you can find all these
          experts in Satta leak number page of our website.
        </p>
        <hr className="hr-border" />
        <p>
          Satta-no.com में आपका स्वागत है हमारी वेबसाइट{" "}
          <a href="Sattakingrecordchartgali">SATTA KING</a>बाजार की अग्रणी
          वेबसाइटों में से एक है, हमारी साइट 2018 में सभी सट्टा किंग गेम्स और
          सट्टा किंग रिकॉर्ड चार्ट के परिणाम दिखाने के लिए विकसित की गई है, हम
          किसी भी सट्टा खेल में शामिल नहीं हैं गतिविधि। हमारी वेबसाइट में हमारे
          उपयोगकर्ता सट्टा परिणाम सुपर फास्ट प्राप्त कर सकते हैं जो आपको अपने
          मोबाइल में तेजी से सट्टा परिणाम प्राप्त करने में मदद करते हैं, आप
          हमारे सट्टा राजा रिकॉर्ड चार्ट पृष्ठ में सट्टा राजा बाजार के सभी
          सर्वश्रेष्ठ गेम का सट्टा रिकॉर्ड पा सकते हैं जो बहुत उपयोगकर्ता के
          अनुकूल है और आरामदायक। इस पृष्ठ में आप राजकोट सट्टा राजा, बहादुरगढ़
          सट्टा, चारमीनार सट्टा राजा, देसावर, फरीदाबाद, गाजियाबाद, गली, दिसावर
          दिवस, मैसूर सट्टा राजा, श्री हरि सट्टा, मैक्स गोल्ड, जैसे सट्टा बाजार
          के सभी सर्वश्रेष्ठ खेलों का रिकॉर्ड चार्ट पा सकते हैं। शेर-ए-अफगान,
          राजश्री सट्टा, गोल्डन सट्टा, सुंदरम, अपस्किंग सट्टा, पेशवा सट्टा किंग,
          ताज सट्टा, हरियाणा, हिंदुस्तान, शोलापुर, चेतक, फैज़ना सत्त, पवनपुत्र
          सट्टा, श्री गणेश, अरब काउंटी, ड्रीम 7, यूपी सट्टा किंग। , गुड़गांव,
          पंजाब दिवस, नर्मदा, मुंबई दिवस, शालीमार, स्वराज सट्टा किंग, दिसावर
          365, इलाहाबाद, मीना बाजार, तेजस, सट्टा किंग, लकी 7, मिर्जापुर, कलश,
          सट्टा बाजार, गुजरात मार्केट, सुपर सट्टा और बहुत अधिक प्रसिद्ध खेल
          सट्टाबाजार का। हम अपने उपयोगकर्ता को अपना गेम परिणाम अपडेट करने की
          अनुमति देते हैं। यदि आप हमारी साइट में अपना सट्टा किंग गेम जोड़ना
          चाहते हैं, तो आपको साइट व्यवस्थापक से संपर्क करना होगा। हमारी वेबसाइट
          में आप सट्टा लीक नंबर के बारे में भी पोस्ट पा सकते हैं, इन पोस्टों को
          अलग-अलग सट्टेबाजों द्वारा पोस्ट किया गया है, जिनके पास पुराने सट्टा
          रिकॉर्ड चार्ट के लिए आगामी जूट का अनुमान लगाने और निकालने में एक
          व्यापक अनुभव है। ये विशेषज्ञ आपको किसी भी{" "}
          <a href="Sattakingrecordchartgali">सट्टा किंग </a> गेम को जीतने में
          मदद कर सकते हैं। आप उनसे संपर्क करके सट्टा राजा के किसी भी खेल को भी
          जीत सकते हैं। आप हमारी वेबसाइट के सट्टा लीक नंबर पेज में इन सभी
          विशेषज्ञों को पा सकते हैं।
        </p>
      </div>
      {/* Sattano.com */}
      <ins
        className="adsbygoogle"
        style={{ display: "block" }}
        data-ad-client="ca-pub-7108667654778024"
        data-ad-slot={6000997688}
        data-ad-format="auto"
        data-full-width-responsive="true"
      />
      {/*  */}
      <div className="content">
        <h3> Is the SATTA KING 786 real? </h3>
        <p>
          SATTA KING 786 was the game of{" "}
          <a title=" SATTA KING" href="Sattakingrecordchartgali">
            SATTA KING
          </a>{" "}
          market like Desawar, Gali, and Ghaziabad, It was started in 2015, many
          peoples started playing this game. I may be playing now a day also in
          some parts of the state in India. People really enjoyed this game at
          that time. there are many similar games of SATTA KING 786 like Shri
          Ganesh Satta, Rajkot SATTA KING, Satta Bazar,{" "}
          <a title=" SATTA KING" href="Sattakingrecordchartgali">
            SATTA KING
          </a>
          , Peshawar Satta, and many more. you can also play these games instead
          of SATTA KING 786. There is a good crowd involved in playing these
          games. I hope you may get your answer from this article.
        </p>
      </div>
      <div
        align="center"
        style={{ backgroundColor: "#000", border: "3px solid #FFF" }}
      >
        <div
          className="quicklink"
          style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
          }}
        >
          <a href="Privacypolicy">PRIVACY POLICY</a>
          <a href="Sitemap">SITEMAP</a>
          <a href="Disclaimer">DISCLAIMER</a>
        </div>
        <img style={{ margin: "auto" }} src={wallet} />

        <style
          dangerouslySetInnerHTML={{
            __html:
              "\n            .footlink a {\n                text-decoration: none;\n                background-color: yellow;\n                border-radius: 10px;\n                border-color: red;\n                padding: 9px;\n                color: #000;\n\n            }\n\n            .footlink {\n                position: relative;\n                top: 12px;\n            }\n        ",
          }}
        />
        <div
          className="footlink"
          style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
          }}
        >
          <a href="Sattakingrecordchartgali"> SATTA KING 2019</a> |
          <a href="Sattakingrecordchartgali">Satta result</a> |
          <a href="/"> SATTA KING</a> |
          <a href="Vipsattaking">VIP SATTA KING</a> |
          <a href="Sattakingup"> SATTA KING up </a> |
          {/* <a href="satta-king-live-result.html">Satta live result</a> | */}
          {/* <a href="satta-number.html">Satta number</a> | */}
          {/* <a href="satta-leak-number.html">Satta leak number</a> */}
        </div>
        <h1 style={{ color: "black", opacity: 0 }}> SATTA KING</h1>
        <hr />
        <font size={4} color="white">
          <b>
            SITE DEGINE BY SUNIL BHAI <br />
            APNI WEBSITE BANWANE KE LIYE CONTACT US.. WEBMAKER
            <br /> <br />
          </b>
        </font>
      </div>
      <table className="footer-pages">
        <tbody>
          <tr>
            <td width="20%">
              <a title="satta smart" href="Sattasmart">
                <font color="#ffffff">SATTA SMART</font>
              </a>
            </td>
            <td width="25%">
              <a title="vip satta" href="Vipsattaking">
                <font color="#ffffff">VIP SATTA</font>
              </a>
            </td>
            <td width="30%">
              <a title=" SATTA KING up" href="Sattakingup">
                <font color="#ffffff"> SATTA KING UP</font>
              </a>
            </td>
            <td width="25%">
              <a title="up game king" href="Upgameking">
                <font color="#ffffff">UP GAME</font>
              </a>
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );
}

export default Upgameking;
