import telegram from "../../assets/image/telegram.png";
import whatsapp from "../../assets/image/whatsapp.png";
import main_kalyan_apk from "../../assets/image/main_kalyan.apk";
// import Button from 'react-bootstrap/Button';
import { IoLogoWhatsapp } from "react-icons/io";
import Button from 'react-bootstrap/Button';


import { FcDownload } from "react-icons/fc";
import { FaDownload } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import React, { useState, useEffect } from 'react'
import axios from "axios";

function Footer() {

  const [useteligram, setteligram] = useState("");
  // alert(useteligram)
  const [usewhatup, setwhatup] = useState("");
  const fetchAdvertisements1 = async () => {
    try {
      const response = await fetch(`https://sattakingresult.xyz/ResultApi/public/api/genral-setting`, { method: 'post' });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      console.warn(data); // Log the response data
      setwhatup(data.data.whatsapp_link);
      setteligram(data.data.telegram_link);

    } catch (error) {
      console.error('Error:', error);
    }
  };

  useEffect(() => {
    fetchAdvertisements1();
  })



  return (
    <>









      <div>
        <a
          href={useteligram}
          className="float telegram"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={telegram} alt="Telegram" />

        </a>


        <div>





          <a href={`https://wa.me/+${usewhatup}`}

            className="float play_now d-flex"
            target="_blan"
            rel="noopener noreferrer"
          >
            {/* <img src={whatsapp} alt="Telegram" /> */}
            <  Button variant="primary">PLAY NOW</Button>{' '}


          </a>




          <div id="footer_right" onClick={() => window.location.reload()} ><a href=" "> Refresh   </a></div>
        </div>







      </div>



    </>


  );
}

export default Footer;
