import React from 'react'

function Disclaimer() {
  return (
    <>

      <div>
        {/* Mirrored from satta-no.co.in/disclaimer.php by HTTrack Website Copier/3.x [XR&CO'2014], Mon, 08 Apr 2024 10:29:26 GMT */}
        {/* Added by HTTrack */}<meta httpEquiv="content-type" content="text/html;charset=UTF-8" />{/* /Added by HTTrack */}
        <meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />
        <title>Satta King   |  Satta King2019 | satta-no.com</title>
        <meta name="Description" content=" Satta King,  Satta King,  Satta King up, Gali result, Desawar result, Faridabad result, Gaziyabad " />
        <meta name="Keywords" content=" Satta King record chart,  Satta King live result ,  Satta King live , live  Satta King" />
        <link rel="stylesheet" href="boostrapp.css" />
        <link href="css/styledcfddcfd.css?id=4" rel="stylesheet" type="text/css" />
        <link href="backgrounddcfddcfd.html?id=4" rel="stylesheet" type="text/css" />
        <link rel="icon" href="images/fav.html" type="image/gif" sizes="16x16" />
        <meta name="viewport" content="width=device-width" />
        <table className="pages">
          <tbody><tr><td width="20%">
                <a title=" Satta King" target="_blank" href="index-3.html">
                  <font color="#ffffff">HOME</font></a> 
              </td>
              <td width="25%">
                <a href="satta-king-record-chart-gali-2.html">
                  <font color="#ffffff">SATTA CHART</font></a> 
              </td>
              <td width="30%">
                <a href="satta-king-2.html">
                  <font color="#ffffff">SATTA MATKA</font></a> 
              </td>
              <td width="25%">
                <a href="black-satta-king-leak-number-2.html">
                  <font color="#ffffff">SATTA LEAK</font></a> 
              </td>
            </tr></tbody></table>
        <div className="king">       
          <h1>DISCLAIMER</h1>
        </div>
        <div className="content">
          <h3>❋ DISCLAIMER ❋</h3>
          <p align="justify" color="#0008ff" style={{fontSize: '17px'}}>
            Gambling may be a state subject, and solely states in Republic of India square measure entitled to formulate laws for gambling activities at intervals their several states. the general public Gambling Act of 1867 may be a central law that prohibits running or being accountable of a public gambling hell. The penalty for breaking this law may be a fine of Rs. or imprisonment of up to three months. in addition, this Act prohibits visiting gambling homes. A fine of Rs.100 or imprisonment of up to 1 month is that the penalty.
            Indian law classifies games into 2 broad classes viz. game of talent and game of probability. The Supreme Court of Republic of India has, as an example, held
            The game of Rummy isn't a game entirely of probability just like the ‘three-card’ game mentioned within the Madras case to that we tend to were referred. The ‘three card’ game which fits underneath completely different names like ‘flush’, ‘brag’ etc. may be a game of pure probability. Rummy, on the opposite hand, needs an exact quantity of talent as a result of the autumn of the cards needs to be memorized and also the build up of Rummy needs appreciable talent in holding and discarding cards. We cannot, therefore, say that the sport of Rummy may be a game of entire probability. it's primarily and predominantly a game of talent.
            The Information Technology Act 2000 regulates cyber activities in Republic of India doesn't mention the word Gambling or gambling thereby the act was left for interpretation by the Courts that have refused to look at the matter. Further, on-line gambling may be a prohibited offense within the state of geographic area underneath the "Bombay Wager Act" <a href="index-3.html"> Satta King</a> .
            Only 3 states permit casinos, Goa , Daman and Sikkim. There square measure 2 casinos in Sikkim known as Casino Sikkim and Casino Mah-Jongg and ten in state, of that six square measure land primarily based and 4 square measure floating casinos that operate the Mandovi watercourse. The floating casinos in state square measure Casino Deltin Royale, Casino Deltin Jaqk, Casino Pride and Casino Pride a pair of. whereas the primary 2 square measure controlled by the Deltin cluster, the latter 2 square measure managed by the Pride cluster. per the state, Daman and Diu Public Gambling Act, 1976 casinos are often got wind of solely at 5 star hotels or offshore vessels with the previous permission of the govt.. This has semiconductor diode the Deltin cluster to open the primary land primarily based Casino in Daman that is open currently. News reports additionally counsel that Visakhapatnam is additionally being looked on because the next casino destination.
            The penalty for being caught gambling on-line has been a fine of ₹ ninety,000 per day since 2009 - <a href="index-3.html"> Satta King</a>
          </p>
        </div>
        <div align="center" style={{backgroundColor: '#000', border: '3px solid #FFF'}}>
        <div className="quicklink"  style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
            <a href="Privacypolicy">PRIVACY POLICY</a>
            <a href="Sitemap">SITEMAP</a> 
            <a href="Disclaimer">DISCLAIMER</a>  
          </div>
          <a href="http://www.dmca.com/Protection/Status.aspx?ID=502f19cc-9e24-4e51-b039-8f110b802712" title="Satta King" className="dmca-badge"> <img src="images.dmca.com/Badges/dmca-badge-w200-5x1-0606020602.html?ID=502f19cc-9e24-4e51-b039-8f110b802712" alt="Satta King" /></a>  <br />
          <a href="satta-king-record-chart-gali-2.html"> Satta King 2019</a> | 
          <a href="satta-king-live-record-chart.html">Satta result</a> | 
          <a href="index-4.html"> Satta King</a> | 
          <a href="vip-satta-king-2.html">vip  Satta King</a> | 
          <a href="satta-king-up-2.html"> Satta King up </a> | 
          <a href="satta-king-live-result-2.html">Satta live result</a> | 
          <a href="satta-number-2.html">Satta number</a> | 
          <a href="satta-leak-number-2.html">Satta leak number</a>
          <hr />
          <font size={4} color="white"><b>SITE DEGINE BY SUNIL BHAI <br />APNI WEBSITE BANWANE KE LIYE CONTACT US.. WEBMAKER<br />  <br /></b></font> 
        </div>
        <table className="footer-pages">
          <tbody><tr><td width="20%">
                <a title="satta smart" href="satta-smart-2.html">
                  <font color="#ffffff">SATTA SMART</font></a> 
              </td>
              <td width="25%">
                <a title="vip satta" href="vip-satta-king-2.html">
                  <font color="#ffffff">VIP SATTA</font></a> 
              </td>
              <td width="30%">
                <a title=" Satta King up" href="satta-king-up-2.html">
                  <font color="#ffffff"> Satta King UP</font></a> 
              </td>
              <td width="25%">
                <a title="up game king" href="Upgameking">
                  <font color="#ffffff">UP GAME</font></a> 
              </td>
            </tr></tbody></table>
        {/* Mirrored from satta-no.co.in/disclaimer.php by HTTrack Website Copier/3.x [XR&CO'2014], Mon, 08 Apr 2024 10:29:28 GMT */}
      </div>

    
    </>
  )
}

export default Disclaimer